<template>
    <div class="Details">
        <div class="bg">
            <div class="box">
                <div class="details-title">{{ data.title || data.name }}</div>

                <div class="content" v-html="data.content || data.info"></div>
                <div class="btn-box">
                    <span class="btn" @click="goList">返 回</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { queryGwNewsDetail,queryGwIndustryDetail } from '@/api/basic'

export default {
    name: 'Details',
    data() {
        return {
            type: this.$route.params.type,
            id: this.$route.params.id,
            data: {}
        }
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            if (this.type == 'news') {
                this.getNewsDetailsData()
            } else if (this.type == 'Industry') {
                this.getIndustryDetailsData()
            }
        },
        getNewsDetailsData() {
            let params = {
                id: this.id
            }
            queryGwNewsDetail(params).then(res => {
                this.data = res.data
            })
        },
        getIndustryDetailsData() {
            let params = {
                id: this.id
            }
            queryGwIndustryDetail(params).then(res => {
                this.data = res.data
            })
        },
        goList() {
            this.$router.go(-1)
        }
    }
}
</script>
<style lang="less" scoped>
.swiper-container {
    width: 600px;
    height: 350px;
    /deep/ .swiper-slide {
        width: 100%;
    }
    .swiper-pagination {
        .swiper-pagination-bullet-active {
            background-color: #f29b76;
        }
    }
}
.Details {
    background: #f2f2f2;
    padding: 40px;
    .bg {
        margin: 0 auto;
        width: 1300px;
        background: #fff;
        padding: 58px 40px;

        .box {
            pre {
                white-space: pre-wrap;
                word-break: break-all;
            }
            .details-title {
                text-align: center;
                font-size: 28px;
                margin-bottom: 30px;
            }
            .content {
                margin-bottom: 50px;
            }
            .btn-box {
                padding: 30px 0;
                text-align: center;
            }
            .btn {
                width: 160px;
                height: 40px;
                display: inline-block;
                text-align: center;
                line-height: 40px;
                background: #f5f5f5;
                color: #999999;
                cursor: pointer;
            }
        }
    }
}
</style>
